import React, { useEffect } from "react";
import { Header } from './Header';
import './css/app.css';
import './css/bootstrap.css';
import { Route, Routes } from "react-router-dom";
import { Home } from "./Home";
import { Footer } from "./Footer";
import Contact from "./Contact";
import axios from "axios";
import { config } from "./reactEnv";
import About from "./About";
import Services from "./Services";
import Prices from "./Prices";
import Design from "./Design";
import Languages from "./Languages";
import { Helmet } from "react-helmet";
import Booking from "./Booking";
import Offer from "./Offer/Offer";
import DentalLab from "./DentalLab/DentalLab";



function App() {
  let iteration = 0;
  if (iteration == 0) {
    useEffect(() => {
      axios.post(`${config.url}api/client`).then((response) => {
        console.log(response)
        iteration = 1;
      }).catch((e) => {
        console.log(e)
      })
    }, []);
  }

  return (
    <div className="App">
      <div className="scroll-watcher"></div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about-us" element={<About />}/> */}
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/prices" element={<Prices />} />
        <Route path="/design" element={<Design />} />
        <Route path="/lang" element={<Languages />} />
        <Route path="/book" element={<Booking />} />
        <Route path="/offre" element={<Offer />} />
        <Route path="/dentalLab" element={<DentalLab />} />
      </Routes>
      <Footer />
      <div className="reference">
        © 2024 All Rights Reserved By CodinHelp
      </div>
    </div>

  );
}

export default App;
