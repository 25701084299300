import React from "react";
import { SubscribeForm } from "./SubscribeForm";

export const Footer = () => {
    let prefixurl = window.location.pathname;
    return (
        <footer style={{ display: prefixurl === "/dentalLab" ? 'none' : 'block' }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <a href="mailto:anthony.saliban@gmail.com" target="_blank" rel="noopener noreferrer"><img src={require('./images/envelope-white.png')} alt=""/>anthony.saliban@gmail.com</a>

                    </div>
                    <div className="col-lg-4 phone">
                        <a href="https://wa.me/96176197840" target="_blank" rel="noopener noreferrer"><img src={require('./images/telephone-white.png')} alt=""/>+96176197840</a>

                    </div>
                    <div className="col-lg-4 linkedIn">
                        <a href="https://www.linkedin.com/in/anthony-saliba-606279225/" target="_blank" rel="noopener noreferrer"><img src={require('./images/linkedin.png')} alt=""/></a>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12 subscribe">
                        <SubscribeForm/>
                    </div>
                </div>
            </div>


        </footer>

    );
}