import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Services = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        document.title = 'Services | CodingHelp';

    }, [])

    let canonicalUrl = window.location.href;

    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY + window.innerHeight;
          const elements = document.querySelectorAll('.desc');
            
          elements.forEach(element => {
            const elementPosition = element.offsetTop;
    
            if (scrollPosition > elementPosition) {
              setIsVisible(prevState => ({
                ...prevState,
                [element.id]: true // Use element ID as key to track visibility state for each element
              }));
            } else {
              setIsVisible(prevState => ({
                ...prevState,
                [element.id]: false
              }));
            }
          });
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Clean up event listener on unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    return (
        <div className="services">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className="subheader">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1>Our Services</h1>
                        </div>
                        <div className="col-md-6">
                            <img src={require('./images/contactus.jpg')}/>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="topSection">
                <div className="container">
                    <div className="title"><h1>Coding Help and Website Development Services</h1></div>
                    <div className="desc">Welcome to our Coding Help and Website Development services!<br /> Experienced developers and coders passionate about turning your digital dreams into reality. Whether you're a business owner looking to establish an online presence or an individual with a unique project in mind,<br /> we're here to assist you with all your coding and web development needs.</div>
                </div>
            </div> */}
            <div className="mission">
                <div className="container">
                    <div className="title">How can we help you?</div>
                    <div className="desc"><b>1. Custom Website Development:</b>We specialize in creating unique, responsive websites tailored to your specific needs. Our front-end and back-end development expertise allows us to build dynamic, interactive, and user-friendly websites that leave a lasting impression.</div>
                </div></div>
            <div className="mission">
                <div className="container">
                    {/* <div className="title"> Why Choose CodingHelp?</div> */}
                    <div className="desc"><b>2. Front-End Development:</b>We are proficient in a wide range of front-end technologies, including HTML, CSS, JavaScript, and various frameworks like React. Our front-end development ensures a seamless user experience and an attractive, modern design.</div>
                </div></div>
            <div className="mission">
                <div className="container">
                    {/* <div className="title">Our Approach</div> */}
                    <div className="desc"><b>3. Back-End Development: </b>Our back-end developers are skilled in server-side programming using languages such as Python, PHP, Java, asp.net, and more. We create robust, secure, and scalable back-end systems that power your website's functionality.</div>
                </div></div>
            <div className="mission">
                <div className="container">
                    {/* <div className="title">Affordable Pricing</div> */}
                    <div className="desc"><b>4. E-Commerce Solutions:</b>If you're looking to start an online store, we can help you set up an e-commerce platform with secure payment gateways and inventory management to maximize your sales potential.</div>
                </div></div>
            <div className="mission">
                <div className="container">
                    {/* <div className="title">Our Commitment</div> */}
                    <div className="desc"><b>5. Content Management Systems (CMS):</b>We can integrate popular CMS solutions like WordPress, Drupal, or Joomla, enabling you to easily manage your website's content and updates without extensive technical knowledge.</div>
                </div></div>
            <div className="mission">
                <div className="container">
                    {/* <div className="title">Join Us on the Coding Adventure</div> */}
                    <div className="desc"><b>6. Mobile-Friendly Design: </b>In today's mobile-centric world, we ensure that your website is responsive and optimized for mobile devices, providing a seamless user experience across all screens.</div>
                </div></div>
            <div className="mission">
                <div className="container">
                    {/* <div className="title">Join Us on the Coding Adventure</div> */}
                    <div className="desc"><b>7. Ongoing Support and Maintenance:</b> Our commitment doesn't end with the launch of your website. We offer ongoing support and maintenance services to keep your site running smoothly, secure, and up-to-date.</div>
                </div></div>
            <div className="mission">
                <div className="container">
                    {/* <div className="title">Join Us on the Coding Adventure</div> */}
                    <div className="desc"><b>Timely Delivery: </b> We understand the importance of timelines. You can count on us to deliver your project on schedule without compromising quality.</div>
                </div>
            </div>
            <div className="ourdesign">
                <div className="container">
                    <h1>
                        Master the Code. Explore Our Comprehensive Guides Today!
                    </h1>
                    <div className="servicesbooking">
                        <Link to="/lang" className="book">
                            START EXPLORING NOW!
                        </Link>
                    </div>
                </div>

            </div>


        </div>
    )
}

export default Services;