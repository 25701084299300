import env from "react-dotenv";
// Production URL
const production = {
  url:"https://codinhelp.com/backend/public/"
    // url: "https://aspapis.codinhelp.com/"
    // url: "https://localhost:7188/"
  };
  
  // Development URL
  const development = {
    url: "http://codinhelp.ls/"
    // url: "http://aspapis.codinhelp.com/"

  };
  // 
  export const isDevMode = process.env.NODE_ENV === "development";
  
  // export const qrTimeoutMinutes = isDevMode ? 10 : 1;
  export const config = isDevMode ? development : production;