import axios from "axios";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import Rating from "react-rating";
import Select from "react-select";
import { config } from "./reactEnv";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Contact = () => {

    const [number, setPhoneNumber] = useState("");
    const [valid, setvalid] = useState(true);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [getdata, setData] = useState({ rating: 0, fname: "", lname: "", email: "", message: "", number: "", country: "" })
    const [rating, setrating] = useState("")

    // useEffect(() => {
    //     fetch(
    //         "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    //     )
    //         .then((response) => response.json())
    //         .then((data) => {
    //             setCountries(data.countries);
    //             setSelectedCountry(data.userSelectValue);
    //             setData({ ...getdata, country: data.userSelectValue.label });
    //         });
    // }, []);
    useEffect(() => {
        document.title = 'Contact us | CodingHelp';
    }, []);
    let canonicalUrl = window.location.href;

    const handleSubmit = (e) => {
        e.preventDefault();
        const btn = document.getElementById('btn');
        btn.value = "Submitting...";
        axios.post(`${config.url}api/contact`, {
            email: getdata.email,
            fname: getdata.fname,
            lname: getdata.lname,
            phone: getdata.number,
            // country: countries,
            message: getdata.message,
            feedback: parseInt(getdata.rating)
        }).then((response) => {
            if (response.data.status) {
                btn.value = "Submitted";
                btn.disabled = true
                console.log(response)
            } else {
                btn.value = "Try again";
            }
        }).catch((e) => {
            btn.value = "Try again";
            console.log(e)
        })

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...getdata, [name]: value });
    }

    const handleNumberChange = (value, selectedCountry) => {
        setPhoneNumber(value);
        setCountries(selectedCountry.name)
        setData({ ...getdata, number: value });
        setvalid(validatePhoneNumber(value));
    }

    const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberPattern = /^\d{10,}$/;

        return phoneNumberPattern.test(phoneNumber);
    };

    const handleRateChange = (rating) => {
        console.log(rating)
        // if(rating=''){
        //     rating=0;
        // }

        setrating(rating)
        setData({ ...getdata, rating: rating });
        // console.log(rating)
    }


    return (
        <div className="contactform">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className="subheader">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1 className="title">Get In Touch</h1>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./images/contactus.jpg')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={require('./images/contactusimage.avif')} />
                        </div>
                        <div className="col-md-6">
                            <div className="form">
                                <div className="title">Send us a message</div>
                                <form method="post" onSubmit={handleSubmit}>
                                    <input type="text" name="fname" onChange={handleChange} value={getdata.fname} placeholder="First name" required />
                                    <input type="text" name="lname" onChange={handleChange} value={getdata.lname} placeholder="Last name" required />
                                    <input type="email" name="email" onChange={handleChange} value={getdata.email} placeholder="Email" required />
                                    <div className="phonenumber">
                                        <PhoneInput
                                            country={'lb'}
                                            value={number}
                                            onChange={handleNumberChange}
                                            inputProps={{
                                                required: true,
                                            }}
                                            placeholder="Phone number..."
                                        />
                                        {!valid && (
                                            <p className="error">Please enter a valid phone number.</p>
                                        )}
                                    </div>
                                    <textarea name="message" rows={5} value={getdata.message} onChange={handleChange} style={{ width: "100%" }} placeholder="Your Message" />
                                    <input type="submit" id="btn" value={"Submit"} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="ourdesign">
                <div className="container">
                    <h1>
                        Master the Code. Explore Our Comprehensive Guides Today!
                    </h1>
                    <div className="servicesbooking">
                        <Link to="/lang" className="book">
                            START EXPLORING NOW!
                        </Link>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default Contact;