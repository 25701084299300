import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../images/dentalLab/logo1.png";
import logo2 from "../images/dentalLab/logo2.png";
import free from "../images/dentalLab/freecons.webp";
import aff from "../images/dentalLab/affoprice.jpg";
import img1 from "../images/dentalLab/quang-tri-nguyen-VxVO1zrY5F8-unsplash.jpg"
import img2 from "../images/dentalLab/dentures-759929_1280.jpg"
import img3 from "../images/dentalLab/two-dental-whitening-trays-box-it.jpg"
import oblique from "../images/dentalLab/obliquearrow.webp"


const DentalLab = () => {
    const [getService, setService] = useState();
    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash.substring(1);
            console.log(hash)
            setService(hash);
        };
        handleHashChange();
        window.addEventListener('hashchange', handleHashChange);
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);
    let canonicalUrl = window.location.href;
    console.log(window.location.pathname);
    return (
        <div className="dentallab">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <header>
                <nav className="headerDental">
                    <div className="logo1">
                        <img src={logo1} />
                    </div>
                    <ul>
                        <li>
                            <a href="/dentalLab">Home</a>
                        </li>
                        <li>
                            <a href="#about">About</a>
                        </li>
                        <li>
                            <a href="#service">Service</a>
                        </li>
                        <li>
                            <a href="#blog">Blog</a>
                        </li>
                        <li className="logo2">
                            <img src={logo2} />
                        </li>
                    </ul>
                </nav>
                <div className="container-xl">
                    {getService == "service" ? (
                        <>
                            <div className="intro">

                                <h1>
                                    Our Services
                                </h1>

                            </div>
                            <div className="text" style={{ marginTop: "20px" }}>
                                <p>
                                    Check out our services and what we can offer you
                                </p>
                            </div>
                            <div className="row" style={{ marginTop: "60px" }}>
                                <div className="col-md-3">
                                    <div className="img1">
                                        <img src={img1} />
                                        <div className="fleche">
                                            <img src={oblique} />
                                        </div>
                                    </div>
                                    <div className="serv">
                                        DENTURES
                                        <span>+</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="img1">
                                        <img src={img2} />
                                        <div className="fleche">
                                            <img src={oblique} />
                                        </div>
                                    </div>
                                    <div className="serv">
                                        ZIRCONIA TEETH
                                        <span>+</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="img1">
                                        <img src={img3} />
                                        <div className="fleche">
                                            <img src={oblique} />
                                        </div>
                                    </div>
                                    <div className="serv">
                                        CUSTOM TRAY
                                        <span>+</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="intro">

                                <h1>
                                    Crafting Smiles With Precision & Artistry
                                </h1>

                            </div>
                            <div className="text" style={{ marginTop: "20px" }}>
                                <p>
                                    Discover our dedication to providing exceptional healthcare across all aspects of patient care
                                </p>
                            </div>
                            <div className="row" style={{ marginTop: "60px" }}>
                                <div className="col-md-4">
                                    <div className="freeconsultation">
                                        <div className="hd">
                                            <img src={free} />
                                            <p>Free Consultation</p>
                                        </div>
                                        <div className="text2">Get answers to your health related questions with our free sessions with healthcare experts</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="freeconsultation">
                                        <div className="hd">
                                            <img src={aff} />
                                            <p>Affordable Price</p>
                                        </div>
                                        <div className="text2">Access quality healthcare at an affordable cost with our Affordable Medical Services</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </header>
            <footer>
                <div className="container-xl">
                    <b>Contact Us For More Info</b>
                    <div>
                        <a href="#">+123-456-7890</a>
                    </div>
                    <div>Haret Hreik - Doctor's str branch of Dakkash str. Bakri Bldg. 1st Floor</div>
                    <div>
                        <a href="#">@adonis_dental_lab</a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default DentalLab;