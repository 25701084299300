import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";

export const Header = () => {

  const [menuOpen, setMenuOpen] = useState(false);
  const mySidenavRef = useRef(null);
  useEffect(() => {
    if (menuOpen) {
      if (mySidenavRef.current) {
        mySidenavRef.current.style.width = "250px";
      }
    } else {
      if (mySidenavRef.current) {
        mySidenavRef.current.style.width = "0px";
      }
    }
  }, [menuOpen])

  let prefixurl = window.location.pathname;

  return (
    <header style={{ display: prefixurl === "/dentalLab" ? 'none' : 'block' }}>
      <nav className="container navbar">
        <Link to="/" className="title">Coding Help</Link>
        <div className="menu" onClick={() => { setMenuOpen(!menuOpen) }}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul>
          <li>
            <Link to="/offre">Free webpage</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/prices">Prices</Link>
          </li>
        </ul>
        <button className="book">
          <Link to="/contact">BOOK CONSULTATION
          </Link>
        </button>
      </nav>
      <div className="sidenav" ref={mySidenavRef} style={{ width: '0px' }}>
        <button className="close" onClick={() => setMenuOpen(!menuOpen)}>X</button>
        <ul>
          <hr />
          <li>
            <Link to="/offre" onClick={() => setMenuOpen(!menuOpen)}>Free webpage</Link>
          </li>
          <hr />
          <li>
            <Link to="/services" onClick={() => setMenuOpen(!menuOpen)}>Services</Link>
          </li>
          <hr />
          <li>
            <Link to="/contact" onClick={() => setMenuOpen(!menuOpen)}>Contact</Link>
          </li>
          <hr />
          <li>
            <Link to="/prices" onClick={() => setMenuOpen(!menuOpen)}>Prices</Link>
          </li>
          <hr />
        </ul>
      </div>
    </header>

  );
};

