import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";

const Prices = () => {
    const history = useNavigate();

    useEffect(() => {
        document.title = 'Prices | CodingHelp';
    }, [])
    const handleClick = (b) => {
        localStorage.setItem('package',b)
        history('/book')
    }
    let canonicalUrl = window.location.href;
    return (
        <div className="services">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className="subheader">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1>Our Prices</h1>
                        </div>
                        <div className="col-md-6">
                            <img src={require('./images/contactus.jpg')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="pricing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="fortitle">
                                    <div className="title">
                                        Basic Package - <span>$100</span>
                                    </div>
                                    <div className="description">
                                        Perfect for small businesses or individuals looking to establish their online presence with a simple yet professional website.
                                    </div>
                                </div>

                                <ul>
                                    <li>Up to 5 pages (e.g., Home, About Us, Services, Contact)</li>
                                    <li>Custom design based on your brand identity</li>
                                    <li>Responsive layout for mobile and tablet devices</li>
                                    <li>Basic SEO optimization</li>
                                    <li>Contact form integration</li>
                                    <li>Social media integration</li>
                                    <li>Free Domain</li>
                                    <li>Free hosting</li>
                                </ul>

                                <div className="deleverytime">
                                    Delivery time: <span>1 - 2 weeks</span>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <button className="book" onClick={()=>handleClick("Basic Package")}>
                                    BOOK NOW
                                </button>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="fortitle">
                                    <div className="title">
                                        Advanced Package - <span>$220</span>
                                    </div>
                                    <div className="description">
                                        Ideal for businesses looking for a more comprehensive online presence with advanced features and functionality.
                                    </div>
                                </div>
                                <ul>
                                    <li>Up to 10 pages with custom design and advanced layout options</li>
                                    <li>Integration of content management system (e.g., WordPress) for easy content updates</li>
                                    <li>Advanced SEO optimization including keyword research and on-page optimization</li>
                                    <li>E-commerce functionality with shopping cart and payment gateway integration</li>
                                    <li>Blog setup for content marketing and engagement</li>
                                    <li>Custom features and functionality based on specific requirements</li>
                                    <li>Custom logo design</li>
                                    <li>Maintenance and support : 50$/month</li>
                                </ul>
                                <div className="deleverytime">
                                    Delivery time: <span>2 - 3 weeks</span>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <button className="book" onClick={()=>handleClick("Advanced Package")}>
                                    BOOK NOW
                                </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="fortitle">
                                    <div className="title">
                                        Custom Package - <span>$300+</span>
                                    </div>
                                    <div className="description">
                                        Tailored to meet the unique needs and requirements of your business.
                                    </div>
                                </div>
                                <ul>
                                    <li>Comprehensive consultation to understand your goals and objectives</li>
                                    <li>Custom design and development based on your specifications</li>
                                    <li>Integration of specific functionalities or third-party services</li>
                                    <li>Ongoing support and maintenance as needed</li>
                                    <li>Any additional services required will be quoted based on your specific needs.</li>
                                    <li>Maintenance and support : 80$/month</li>
                                </ul>
                                <div className="deleverytime">
                                    Delivery time: <span>Customized based on project scope and complexity</span>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <button className="book" onClick={()=>handleClick("Custom Package")}>
                                    BOOK NOW
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ourdesign">
                <div className="container">
                    <h1>
                        Master the Code. Explore Our Comprehensive Guides Today!
                    </h1>
                    <div className="servicesbooking">
                        <Link to="/lang" className="book">
                            START EXPLORING NOW!
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Prices;