import axios from "axios";
import React, { useState } from "react";
import { config } from "./reactEnv";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


export const SubscribeForm = () => {

    const [number, setPhoneNumber] = useState("");
    const [country,setCountry] = useState("");
    const [valid, setvalid] = useState(true);
    const [errortext, setErrortext] = useState({ type: "", text: "" });
    const submitPhoneNumber = (event) => {
        event.preventDefault();
        const btn = document.getElementById('btn');
        btn.textContent="Submitting...";
        if (number !== null && number !== "") {
            axios.post(`${config.url}api/subscribers`, {
                phone: number,
                country: country
            })
                .then((response) => {
                    if (response.data.status) {
                        setErrortext({ type: true, text: "We will contact you soon" });
                        btn.textContent="Submitted";
                        console.log(response)
                    } else {
                        btn.textContent="Subscribe";
                        setErrortext({ type: false, text: response.data.message });
                    }

                })
                .catch((e) => {
                    btn.textContent="Subscribe";
                    console.log(e)
                    setErrortext({ type: false, text: "server error" });
                });
        } else {
            btn.textContent="Subscribe";
            setErrortext({ type: false, text: "required field" });
        }
        // setPhoneNumber(number);

    }
    const handleNumberChange = (value,selectedCountry) => {
        setCountry(selectedCountry.name)
        setPhoneNumber(value);
        setvalid(validatePhoneNumber(value));
    }

    const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberPattern = /^\d{10,}$/;

        return phoneNumberPattern.test(phoneNumber);
    };

    return (
        
        <form>
            <label>Get In Touch:</label>
            <div>
            <PhoneInput
                country={'lb'}
                value={number}
                onChange={handleNumberChange}
                inputProps={{
                    required: true,
                }}
                placeholder="Phone number..."
            />
            </div>
            {!valid && (
                <p className="error">Please enter a valid phone number.</p>
            )}
            <button id="btn" onClick={submitPhoneNumber} disabled={!valid}>Subscribe</button>

            <p className={errortext.type ? "success" : "error"}>{errortext.text}</p>

        </form>
    );
};

