import React, { useEffect, useState } from "react";

const Design = () => {
    return (
        <div className="design">
            <div class="container css-design">
                <h2>Welcome to Our Website</h2>
                <p>Thank you for visiting us!</p>
            </div>

            <div class="container animated-box">
                <h2>Welcome to Our Website</h2>
                <p>Thank you for visiting us!</p>
            </div>

            <div class="container advanced-box">
                <h2>Welcome to Our Website</h2>
                <p>Thank you for visiting us!</p>
            </div>

            <div class="container continuous-animation">
                <h2>Welcome to Our Website</h2>
                <p>Thank you for visiting us!</p>
            </div>

        </div>
    )
}

export default Design;