import axios from "axios";
import React, { useEffect, useState } from "react";
import { config } from "./reactEnv";
import { Helmet } from "react-helmet";

const Languages = () => {
    const [getLanguages, setLanguages] = useState([]);
    const [getMap, setMap] = useState([]);

    useEffect(() => {
        document.title = 'Languages | CodingHelp';
        axios.get(`${config.url}api/languages`).then((response) => {
            console.log(response)
            setLanguages(response.data.data)
            setMap(true)
        }).catch((error) => {
            console.log(error)
        })
    }, [])
    let canonicalUrl = window.location.href;


    console.log(getLanguages)
    return (
        <div className="container languages">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <h1>Welcome to Our Programming Languages and Frameworks Exploration Page</h1>
            <p>Explore the fascinating world of programming languages and frameworks with us! Below, you'll find information about various programming languages and frameworks commonly used in software development. Whether you're a seasoned developer or just starting your coding journey, there's something here for everyone.</p>
            <hr />
            <div class="accordion" id="accordionPanelsStayOpenExample">
            </div>
            <div class="accordion" id="accordionExample">
                {getMap && getLanguages.map((value, index) => (
                    <div key={index} class="accordion-item">
                        <h2 class="accordion-header" id={`heading${index}`}>
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                {value.name}
                            </button>
                        </h2>
                        <div id={`collapse${index}`} class="accordion-collapse collapse" aria-labelledby={`heading${index}`}>
                            <div class="accordion-body">
                                <div className="desc"><span>Description:</span>{value.description}</div>
                                <div className="ecosystem"><span>Ecosystem:</span>{value.ecosystem}</div>
                                <span>Features:</span>
                                <ul className="features">{value.features.map((values, index) => (
                                    <li key={index}>
                                        {values}
                                    </li>
                                ))}</ul>
                                <div className="higherVersion"><span>Version:</span>{value.higherVersion}</div>
                                <div className="release"><span>Release:</span>{value.releaseDate}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Languages;
