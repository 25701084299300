import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SubscribeForm } from "../SubscribeForm";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { config } from "../reactEnv";

const Offer = () => {
    useEffect(() => {
        document.title = 'Free Webpage | CodingHelp';

    }, [])
    let canonicalUrl = window.location.href;

    const [number, setPhoneNumber] = useState("");
    const [country,setCountry] = useState("");
    const [valid, setvalid] = useState(true);
    const [errortext, setErrortext] = useState({ type: "", text: "" });
    const submitPhoneNumber = (event) => {
        event.preventDefault();
        const btn = document.getElementById('btn');
        btn.textContent="Submitting...";
        if (number !== null && number !== "") {
            axios.post(`${config.url}api/offre`, {
                phone: number,
                country: country
            })
                .then((response) => {
                    if (response.data.status) {
                        setErrortext({ type: true, text: "We will contact you soon to get more information. Nice to meet you" });
                        btn.textContent="Submitted";
                        console.log(response)
                    } else {
                        btn.textContent="Subscribe";
                        setErrortext({ type: false, text: response.data.message });
                    }

                })
                .catch((e) => {
                    btn.textContent="Subscribe";
                    console.log(e)
                    setErrortext({ type: false, text: "server error" });
                });
        } else {
            btn.textContent="Subscribe";
            setErrortext({ type: false, text: "required field" });
        }
        // setPhoneNumber(number);

    }
    const handleNumberChange = (value,selectedCountry) => {
        setCountry(selectedCountry.name)
        setPhoneNumber(value);
        setvalid(validatePhoneNumber(value));
    }

    const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberPattern = /^\d{10,}$/;

        return phoneNumberPattern.test(phoneNumber);
    };

    return (
        <div className="offre">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className="topSection">
                <div className="container">
                    <div className="title"><h1>Get Your First Home Page for Free!</h1></div>
                    <div className="desc">Sign up today and enjoy a professionally designed home page at no cost! Subscribe now and get a free consultation call to learn more about the page, and in 3-4 days, your web's first page is on track.</div>
                    <form>
                        <div>
                            <PhoneInput
                                country={'lb'}
                                value={number}
                                onChange={handleNumberChange}
                                inputProps={{
                                    required: true,
                                }}
                                placeholder="Phone number..."
                            />
                        </div>
                        {!valid && (
                            <p className="error">Please enter a valid phone number.</p>
                        )}
                        <button id="btn" onClick={submitPhoneNumber} disabled={!valid}>Subscribe</button>

                        <p className={errortext.type ? "success" : "error"}>{errortext.text}</p>

                    </form>
                </div>
            </div>
            <div className="mission">
                <div className="container">
                    <div className="title">Our Expertise</div>
                    <div className="desc">With years of experience and a deep pool of talent, our experts are proficient in various programming languages and technologies. Whether you're a startup looking for a minimalistic website or an enterprise in need of complex software solutions, our team has the skills to deliver excellence.</div>
                </div></div>
            <div className="mission">
                <div className="container">
                    <div className="title"> Why Choose CodingHelp?</div>
                    <div className="desc">Choosing CodingHelp means choosing a reliable partner on your journey to success. We pride ourselves on delivering high-quality websites and software on time and within budget. Our dedication to customer satisfaction sets us apart in the industry.</div>
                </div></div>
            <div className="mission">
                <div className="container">
                    <div className="title">Our Approach</div>
                    <div className="desc">At CodingHelp, we believe that collaboration and innovation are the keys to success. We work closely with our clients, understanding their unique requirements and goals. This collaborative approach enables us to create tailor-made solutions that exceed expectations.</div>
                </div></div>
            <div className="mission">
                <div className="container">
                    <div className="title">Affordable Pricing</div>
                    <div className="desc">We understand that cost is a significant concern for many, which is why we offer competitive and transparent pricing. You can count on us for delivering high-quality code and software solutions that won't break the bank. <a href="/prices">Check here</a></div>
                </div></div>
            <div className="mission">
                <div className="container">
                    <div className="title">Our Commitment</div>
                    <div className="desc">Our commitment to our clients goes beyond the completion of a project. We are here to support you throughout your journey, ensuring that your digital presence thrives and evolves as your needs change.</div>
                </div></div>
            <div className="mission">
                <div className="container">
                    <div className="title">Join Us on the Coding Adventure</div>
                    <div className="desc">Are you ready to embark on a coding adventure? Join us at CodingHelp and experience the difference. Together, we can turn your ideas into reality, one line of code at a time.</div>
                </div></div>


        </div>
    )
}

export default Offer;