import React, { useEffect } from "react";
import { SubscribeForm } from "./SubscribeForm";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Adsense } from "@ctrl/react-adsense";

export const Home = () => {
    useEffect(() => {
        document.title = 'Home | CodingHelp';
    }, []);
    let canonicalUrl = window.location.href;
    return (
        <div className="home">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className="subheader">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-md-1 order-2">
                            <h1 className="title">Welcome to CodingHelp: Your Path to Project Excellence!</h1>
                            <p>Are you diving into the fascinating world of coding, embarking on a journey to create your dream project or a stunning new website? Look no further, because you've just discovered your secret weapon: CodingHelp. We're here to be your trusted companion on this exciting coding adventure.</p>
                            <p>At CodingHelp, we're not just another service – we're your partners in progress. Whether you're a beginner setting out on your coding odyssey or an experienced developer seeking an extra hand, our mission is to make your coding experience smoother, more enjoyable, and highly successful.</p>
                            <button className="book">
                                <Link to="/contact">BOOK CONSULTATION</Link>
                            </button>
                        </div>
                        <div className="col-lg-6 order-md-2 order-1">
                            <img src={require('./images/profile.png')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Adsense
                client="ca-pub-6873004265306888" // Replace with your own AdSense client ID
                slot="1321762828" // Replace with your own ad slot
                style={{ display: 'block' }}
                layout="in-article"
            /> */}
            <div className="serviceshome">
                <div className="container">
                    <h1>
                        We help teams build a business of their dreams with our services.
                    </h1>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="subtitle">
                                Identify Goals
                            </div>
                            <div className="subdesc">
                                We work closely with you to understand and identify your business goals that can be converted into an actionable plan.
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="subtitle">
                                Bring Ideas to Life
                            </div>
                            <div className="subdesc">
                                The ideas and plans that we’ve jotted will then be brought to life. We help and guide you to take the first step and set things up.
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="subtitle">
                                Business Expansion
                            </div>
                            <div className="subdesc">
                                Setting up a business is not enough. We help you think ahead and encourage you to expand your business with a full-proof plan.
                            </div>
                        </div>
                    </div>
                    <div className="servicesbooking">
                        <Link to="/services" className="book">
                            VIEW ALL SERVICES
                        </Link>
                    </div>

                </div>
            </div>
            <div className="whyCodingHelp">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-2">
                            <h1>Why Choose CodingHelp?</h1>
                            <div className="desc">Imagine having a <b>dedicated team</b> of experienced coders and developers who are ready to guide you through every step of your project. From brainstorming ideas and designing elegant solutions to debugging and optimizing your code, we're here to provide the guidance and expertise you need to turn your vision into <b>reality</b>.</div>
                        </div>
                        <div className="col-lg-6 order-1">
                            <img src={require("./images/whycoding.jpg")} alt=""></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ourdesign">
                <div className="container">
                    <h1>
                        Master the Code. Explore Our Comprehensive Guides Today!
                    </h1>
                    <div className="servicesbooking">
                        <Link to="/lang" className="book">
                            START EXPLORING NOW!
                        </Link>
                    </div>
                </div>

            </div>
        </div>


    )
}

